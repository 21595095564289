import Vue from 'vue'
import App from './App.vue'
import VueResource from "vue-resource";
import VueYouTubeEmbed from 'vue-youtube-embed';
//import drag from "vue-dragscroll"

Vue.use(VueYouTubeEmbed);
Vue.use(VueResource);
//Vue.use(drag);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
