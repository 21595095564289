<template>
  <div class="portrait" v-on:click="setActive(portrait.id,portrait.state)" v-bind:class="{activePortrait:active}">
    <div class="portrait-wrapper" v-bind:class="{enemy: portrait.type == 'enemy'}">
      <div class="background">
        <div class="background-line" v-bind:class="{active:active, unlocked: portrait.state == 'unlocked', teased: portrait.state == 'teased', locked: portrait.state == 'locked', enemy: portrait.type == 'enemy', first: first, last: last}">

        </div>
      </div>

      <img class="background-frame" v-bind:class="portrait.type" v-if="portrait.type=='character'" :src="require('@/assets/btn-timeline-medium-bkg-up@2x.png')"/>

      <transition name="fade">
        <img class="background-frame active-frame" v-bind:class="portrait.type" v-if="active && portrait.type=='character'" :src="require('@/assets/btn-timeline-medium-bkg-down@2x.png')"/>
      </transition>

      <img class="background-frame" v-bind:class="portrait.type" v-if="portrait.type=='enemy'" :src="require('@/assets/reveal-btn-timeline-small-btn-timeline-small-bkg-up@2x.png')"/>

      <transition name="fade">
        <img class="background-frame active-frame" v-bind:class="portrait.type" v-if="active && portrait.type=='enemy'" :src="require('@/assets/reveal-btn-timeline-small-btn-timeline-small-bkg-down@2x.png')"/>
      </transition>

      <img class="portrait-image" v-if="portrait.state =='unlocked'" v-bind:class="portrait.type" v-bind:src="portrait.portrait">
      <img class="portrait-image" v-if="portrait.state =='teased'" v-bind:class="portrait.type" v-bind:src="portrait.teasedPortrait">
      <img class="portrait-image" v-if="portrait.state =='locked'" v-bind:class="portrait.type" v-bind:src="lockedPath">

      <div class="cost-wrapper" v-if="portrait.state == 'teased'">

          <div class="caption">
            UNLOCKS AT
          </div>
          <div class="birdy-wrapper">
            <div class="cost-number">
              {{this.numberWithCommas(portrait.cost)}}
            </div>
          </div>

      </div>
      </div>

    <transition name="fade">
      <div class="arrow" v-if="active">
          <img v-if="portrait.type=='character'" :src="require('@/assets/btn-timeline-arrow-blue.svg')"/>
           <img v-if="portrait.type=='enemy'" :src="require('@/assets/btn-timeline-arrow-red.svg')"/>
      </div>
    </transition>

  </div>
</template>

<script>

import { EventBus } from '../event-bus.js'

export default {
  name: "portrait.vue",
  props: {
    portrait:Object,
    active:Boolean,
    first:Boolean,
    last:Boolean
  },
  data (){
    return{
      lockedPath:"https://sos-reveal.nyc3.cdn.digitaloceanspaces.com/assets/locked-medium.png",
      clicksEnabled:true
    }
  },
  methods: {
    setActive: function(id,state) {
      if (state != 'locked' && this.clicksEnabled)
      EventBus.$emit('activeContentUpdated', (id-1));
    },
    numberWithCommas:function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  mounted: function() {
  }

}
</script>

<style scoped>


.portrait {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.activePortrait, .scrollingEventsBlocked {
  pointer-events: none;
}


.portrait-wrapper {
  height:148px;
  width:154px;
  display:flex;
  position:relative;
  justify-content: center;
  overflow:visible;
}

.portrait-wrapper.enemy {
  width:132px;
}

.portrait-image {
  position:absolute;
  display:block;
  height:148px;
}

.portrait-image.character {
  width:122px;
}

.portrait-image.enemy {
  width:102px;
}

.background {
  position:absolute;
  display:flex;
  width:100%;
  height:100%;
}

.background-frame {
  position:absolute;
  display:block;
  height:148px;
}

.background-frame.character {
  width:122px;
}

.background-frame.character.active-frame{
  filter: drop-shadow(0px 0px 8px #00e1ff);
}

.background-frame.enemy {
  width:102px;
}

.background-frame.enemy.active-frame{
  filter: drop-shadow(0px 0px 8px #FF5E34);
}

.background-line {
  position:absolute;
  display:block;
  width:100%;
  height:4px;
  top:78px;
}

.first {
  width:50%;
  right:0px;
}

.last {
  width:50%;
}

.unlocked{
  background-color:#1a81d3;
}

.teased{
  background: linear-gradient(270deg, rgba(0,65,117,1) 0%, rgba(0,65,117,1) 49%, rgba(26,129,211,1) 50%, rgba(26,129,211,1) 100%);
}

.active {
  background: linear-gradient(90deg, rgba(26,129,211,1) 0%, rgba(0,225,255,1) 11%, rgba(0,225,255,1) 89%, rgba(26,129,211,1) 100%);
}

.active.enemy {
  background: linear-gradient(90deg, rgba(26,129,211,1) 0%, #FF5E34 14%, #FF5E34 86%, rgba(26,129,211,1) 100%)
}

.teased.active {
  background: linear-gradient(270deg, rgba(0,65,117,1) 0%, rgba(0,65,117,1) 50%, rgba(0,225,255,1) 50%,rgba(0,225,255,1) 92%,rgba(26,129,211,1) 100%);
}

.locked {
    background-color:#004175;
}

.cost-wrapper {
  display:flex;
  position:absolute;

  align-items: center;
  flex-direction: column;
  bottom:0;
}

.cost-number {
  font-family:'Barlow Semi Condensed';
  color: #002031;
  font-size:26px;
  margin-left:26px;
  height:30px;
  line-height:1.05;
  
}

@-moz-document url-prefix() {
  .cost-number {
    /*line-height:34px!important;*/
  }
}

.birdy-wrapper {
  width:136px;
  height:38px;
  background-image:url("../assets/reveal-btn-timeline-large-btn-timeline-large-cost-panel@2x.png");
  background-size:136px 38px;
}

.birdy-cost {
  position:absolute;
  width:136px;
}

.caption {
  font-family:"neusa-next-std-condensed";
  position:relative;
  font-size:12px;
  font-weight:600;
  letter-spacing: 0.75px;
  height:16px;
  width:96px;
  color:#ffe899;
}

.arrow {
  display:block;
  position:relative;
  height:50px;
  width:34px;
}

/* MOBILE ------------------ */
@media screen and (max-width:767px) {
  .arrow {
    display:block;
    position:relative;
    height:40px;
  }
}

/* ANIMATIONS ------------------ */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
