<template>
  <div class="achievements">
    <sectionTitle :title=title :description1=description1 :description2=description2 :headerImage="require('@/assets/img-achievements-title.svg')"/>
    <div class="achievements-list">
      <li  v-for="achievement in achievements" v-bind:key="achievement.id">
        <achievementView :achievement="achievement" :active="false"/>
      </li>
    </div>

    <div class="clouds"></div>


  </div>
</template>

<script>
import AchievementView from './achievementView.vue'
import sectionTitle from './sectionTitle.vue'


export default {
  name: "achievementsView.vue",
  components: {
    AchievementView,
    sectionTitle
  },
  data (){
    return{
      title:'Achievements',
      description1:"To spice things up, we are adding 4 key supporting characters up for reveal. It's a long shot, we leave this one in your hands!",
      description2: ""
    }
  },
  props: {
    achievements: Array
  }
}
</script>

<style scoped>

.achievements {
  display:flex;
  position:relative;
  flex-direction:column;
  background-image:url("../assets/bg-pattern-sky-lg.png");
  background-size:1280px 1296px;
  background-repeat:repeat-x;
  background-position: bottom center;
  background-color:#131C23;
  overflow-x:hidden;

}

.clouds {
    background-image:url("../assets/bg-pattern-sky-clouds-lg.png");
    background-size:1280px 368px;
    background-repeat:repeat-x;
    position:absolute;
    width:calc(100% + 1280px);
    height:368px;
    bottom: 0;
    left: 0;
    z-index:1;
    animation: clouds-bottom-slide 60s linear infinite;

}

  @keyframes clouds-bottom-slide {
     from { transform:translate3d(-1280px, 0, 0) }
     to { transform:translate3d(0, 0, 0) }
  }

.achievements-list {
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  margin:auto;
  margin-bottom:250px;
  z-index:5;
}

li {
  list-style-type: none;
  display: inline;
}


#cta {
  display:flex;
  flex-direction:row;
  margin:auto;
}

.cta-button {
  width: 400px;
  padding:20px;
  margin:20px;
}

#steam-wishlist {
  background-color:#2c3e50;
  color:white;
}

#discord {
  background-color:mediumpurple;
}

/* MOBILE ------------------ */

@media screen and (max-width:767px) {
  .achievements{
    background-image:url("../assets/bg-pattern-sky-xs.png");
    background-size:640px 648px;
  }

  .achievements-list {
    margin-bottom:40px;
  }

  .clouds {
    height:298px;
    background-image:url("../assets/bg-pattern-sky-clouds-xs.png");
    background-size:640px 298px;
    width:calc(100% + 640px);
  }

   @keyframes clouds-bottom-slide {
      from { transform:translate3d(-640px, 0, 0) }
      to { transform:translate3d(0, 0, 0) }
  }

}



</style>
