<template>
  <div class="twitter">

    <div id="cta">
      <span id="follow">follow</span> @seaofstarsgame
    </div>

    <div id="follower-count">
      {{this.numberWithCommas(count)}}
    </div>
  </div>
</template>

<script>
export default {
  name: "twitterView.vue",
  props: {
    count:Number,
    nextObjective:Number
  },
  methods: {
    numberWithCommas:function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
}


</script>

<style scoped>

.twitter {
  position:relative;
  display:flex;
  flex-direction:row;
  align-items: center;
  max-width:410px;
  height:54px;
  margin:auto;
  margin-bottom:60px;
  cursor:pointer;

  background-image:url("../assets/btn-cta-large-middle.svg"), url("../assets/btn-cta-large-left.svg"), url("../assets/btn-cta-large-right.svg");
  background-size:cover, 20px 54px, 20px 54px;
  background-position:center center, left center, right center;
  background-repeat:no-repeat, no-repeat, no-repeat;

  transition: transform 0.2s;
  transition-timing-function:cubic-bezier(0,0,0.2,1)
}

.twitter:hover {
  //filter: drop-shadow(0px 0px 8px rgba(0,225,255,0.8));
  transform: scale(1.05);
  animation:glow-pulse 1s ease-in infinite;
  animation-direction: alternate-reverse;
}

 @keyframes glow-pulse {
     from { filter: drop-shadow(0px 0px 8px rgba(0,225,255,0.8)) }
     to { filter: drop-shadow(0px 0px 8px rgba(0,225,255,0)) }
  }

#cta {
  font-family:"neusa-next-std-condensed";
  text-transform: uppercase;
  margin-left:32px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  color:white;
  letter-spacing:1px;
  z-index:2;
  pointer-events: none;
}

#follower-count {
  position:absolute;
  display:block;
  width:135px;
  height:44px;
  right:6px;
  box-sizing:border-box;
  padding-left:44px;
  z-index:3;

  background-image:url("../assets/reveal-btn-follow-large-count-panel@2x.png");
  background-size:135px 44px;

  font-family:'Barlow Semi Condensed';
  color:#002031;
 
  font-weight: 600;
  font-size:28px;
  line-height:1.5;
  pointer-events: none;
  text-align:left;
}

@-moz-document url-prefix() {
  #follower-count {
    /*line-height:48px!important;*/
  }

  #cta{
    margin-top:4px;
  }
}

/* CUSTOM BREAKPOINTS FOR BUTTON MOBILE ------------------ */
@media screen and (max-width:767px) {
  .twitter{
     margin-bottom:15px;
  }
}

@media screen and (max-width:420px) {
  .twitter{
    width:338px;
  }

  span#follow{
    display:none;
  }
}

@media screen and (max-width:364px) { 
  .twitter{ 
    scale:0.9;
  } 
}

@media screen and (max-width:324px) { 
  .twitter{ 
    scale:0.85;
  } 
}

</style>
