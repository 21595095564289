<template>
  <div id="app" >

    <transition name="fade">
      <div id="loading-screen" v-if="!loaded">
          <div id="loading-image">
            <img id="back-arrow" :src="require('@/assets/icn-compendium-loader.svg')"/>
          </div>
      </div>
    </transition>

    <div id="content-wrapper" v-if="!error">


      <div id="sticky-nav">
        <a href="https://seaofstarsgame.co" id="back-button" class="sticky-button" >
          <img id="back-icn" :src="require('@/assets/branding-logo-sea-of-stars-white.svg')"/>
       </a>
        <div id="play-pause-button" class="sticky-button play" @click="toggleMusic()">
          <svg id="play-icn" class="sticky-icon" height="16" viewBox="0 0 10 16" width="10" xmlns="http://www.w3.org/2000/svg">
            <path d="m0 0 10 8-10 8z"/>
          </svg>
          <svg id="pause-icn" class="sticky-icon" height="16" viewBox="0 0 10 16" width="10" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="evenodd"><path d="m0 0h3v16h-3z"/><path d="m7 0h3v16h-3z"/></g>
          </svg>
          <span>Cosmic Knowledge</span>
       </div>
      </div>

      <div id="sabotage-flag">
        <video id="video-flag" width="180" height="180" autoplay muted loop playsinline>
          <source :src="require('@/assets/img-sabotage-flag.mp4')" type="video/quicktime">
          <source :src="require('@/assets/img-sabotage-flag.webm')" type="video/webm">
        </video>
        <img id="img-flag" :src="require('@/assets/img-sabotage-flag-small.svg')">
      </div>
      <div id="header">
        <div id="cloud-top">
        </div>

        <sectionTitle :title=title :description1=descriptionLine1 :description2="descriptionLine2" :headerImage="require('@/assets/icn-compendium.svg')"/>

       <a href="https://twitter.com/seaofstarsgame" target="_blank">
          <twitterView :count="twitterFollowers" :nextObjective="nextObjective"/>
       </a>
        <progressBar :portraits="progressData"/>
      </div>
      <div class="content-view" ref="contentView">
        <contentView :activeItem="activePageData" :twitterFollowers="twitterFollowers" :nextObjective="nextObjective"/>
      </div>
      <achievementsView :achievements="achievementsData"/>
      <ctaFooter/>

      <div id="error" v-if="error">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import progressBar from './components/progressBar.vue'
import contentView from './components/contentView.vue'
import achievementsView from './components/achievementsView.vue'
import twitterView from './components/twitterView.vue'
import sectionTitle from './components/sectionTitle.vue'
import { EventBus } from './event-bus.js'
import ctaFooter from './components/ctaFooter.vue'


export default {
  name: 'App',
  components: {
    progressBar,
    contentView,
    achievementsView,
    twitterView,
    sectionTitle,
    ctaFooter
  },
  data (){
    return{
      title:'Compendium',
      descriptionLine1:"Welcome to the Compendium, where information is cataloged about the many characters of the world of Sea of Stars once they have been revealed.",
      descriptionLine2:"As we are self-publishing, getting the word out is crucial. To that effect, we added enemies, NPCs and bosses to be revealed upon reaching social media milestones. You can help unlocking new reveals by simply following our Twitter account, or inviting a friend to do so!",
      twitterFollowers:0,
      nextObjective:0,
      progressData:null,
      achievementsData:null,
      activePageData:null,
      error:false,
      errorMessage:"",
      loaded:false,
      audio:null,
      sound:false

    }
  },
  methods: {
    turnMusicOff:function() {
      this.audio.pause();
      this.sound = false;
      let btn =  document.querySelector("#play-pause-button");
          btn.classList.remove('pause');
          btn.classList.add('play');
    },
    turnMusicOn:function(){
      this.audio.play();
      this.sound = true;
      let btn =  document.querySelector("#play-pause-button");
          btn.classList.remove('play');
          btn.classList.add('pause');
    },
    toggleMusic:function() {
      if (this.sound) {
        this.turnMusicOff();
      } else {
        this.turnMusicOn();
      }
    },
    getStartingId:function(path) {
      var startingId = 0;

      let cleanedPath = path.split('/').pop();

     console.log("Cleaned path is:"+cleanedPath);
      for (var i = 0; i < this.progressData.length; i++) {
        if (this.progressData[i].safeUrl && cleanedPath.toLowerCase() == this.progressData[i].safeUrl.toLowerCase())
        {
          startingId = this.progressData[i].id-1;
          break;
        }
      }

      console.log("Starting id is:"+startingId);
      return startingId;
    },
    getNextObjective: function() {
      for (var i = 0; i < this.progressData.length; i++) {
        if (this.progressData[i].state == 'teased')
        {
          return this.progressData[i].cost;
        }
        // more statements
      }
    }
  },
  mounted:function() {

    let that = this;
    window.addEventListener("load", function() {
      that.loaded = true;
    });

    this.$http.get("https://sos-reveal.nyc3.cdn.digitaloceanspaces.com/progression/reveal.json")
    .then(response => {
      //console.log("data"+response.data);
      this.twitterFollowers = response.data.followers;
      this.progressData = response.data.progress;
      this.achievementsData = response.data.achievements;
      this.nextObjective = this.getNextObjective();
      //this.activePageData = response.data.progress[0];
      let path = window.location.pathname;

      setTimeout(function(){
        EventBus.$emit('activeContentUpdated', (this.getStartingId(path)));
      }.bind(this), 200);


    })
    .catch(function (error) {
      console.log("error:"+error.response);
      this.error = true;
      this.errorMessage = "Server is down.";
    });

    EventBus.$on('activeContentUpdated', (data) => {
      let object = this;

      setTimeout(function() {
        object.$refs.contentView.style.opacity = 0;
      }, 500);

      this.activePageData = this.progressData[data];
      if(this.activePageData.state == "unlocked"){
        document.title = this.title + ' - '+ this.activePageData.name;
      }else{
        document.title = this.title;
      }
      history.replaceState(null, null, this.activePageData.safeUrl);

      setTimeout(function() {
        object.$refs.contentView.style.opacity = 1;
      }, 500);
    })

    EventBus.$on('youtubePlaying', () => {
      this.turnMusicOff();
    })

    this.audio = new Audio('https://sos-reveal.nyc3.digitaloceanspaces.com/assets/CosmicKnowledge.ogg');
    this.audio.loop = true;

  }
}
</script>

<style>

@import url('https://use.typekit.net/glp2ipl.css');

body {
  background:#1A2024;
}

#content-wrapper{
  position:relative;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  display:flex;
  flex-direction: column;
  max-width:1920px;
  margin:auto;
  background:#1A2024;
}

a {
  text-decoration: none;
  color:white;
}

#loading-screen {
  position:fixed;
  display:flex;
  background-color:#042031;
  height:100%;
  width:100%;
  z-index:2000;
  align-items: center;
  justify-content: center;
}

#sticky-nav{
  position:fixed;
  display:flex;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  z-index:999;
  top:12px;
  margin-left:12px;
}

.sticky-button {
  display:flex;
  align-items:center;
  height:48px;
  background:rgba(0,40,61,0.91);
  color:white;
  font-family: "neusa-next-std-condensed";
  font-size:13px;
  font-weight:500;
  text-transform: uppercase;
  padding:0 16px 0 16px;
  border-right:1px solid #1A5B79;
  cursor:pointer;
}

.sticky-button:first-child{
  border-radius: 2px 0 0 2px;
}

.sticky-button:last-child{
  border-right:none;
  border-radius: 0 2px 2px 0;
}

.sticky-button:hover{
  background:#00c1ed;
}

.sticky-button:hover svg{
  fill:#ffffff;
}

.sticky-icon {
  margin-right:8px;
  fill:#00c1ed;
}

#back-icn{
  height:25px;
}

.play #play-icn{ display:block; }
.play #pause-icn{ display:none; }

.pause #play-icn{ display:none; }
.pause #pause-icn{ display:block; }

#sabotage-flag {
  position:absolute;
  top:0;
  right:20px;
  z-index:999;
}

#sabotage-flag #img-flag{
  display:none;
}

#header {
  position:relative;
  display:block;
  background-image:url("./assets/bg-pattern-sky-reversed-lg.png");
  background-repeat:repeat-x;
  background-size:1280px 1296px;
  background-color:#131C23;
  height:100%;
  overflow-x:hidden;
}

#cloud-top {
  position:absolute;
  display:block;
  background-image:url("./assets/bg-pattern-sky-clouds-reversed-lg.png");
  background-size:1280px 368px;
  background-repeat:repeat-x;
  width:calc(100% + 1280px);
  height:368px;
  animation: clouds-slide 60s linear infinite;
}

@keyframes clouds-slide {
    from { transform:translate3d(-1280px, 0, 0) }
    to { transform:translate3d(0, 0, 0) }
}

/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  #sticky-nav{
    display:none;
  }

  #sabotage-flag {
    right:0;
  }

  #sabotage-flag #img-flag{
    display:block;
  }

  #sabotage-flag #video-flag{
    display:none;
  }

  #header {
    background-size:640px 648px;
  }

  #cloud-top{
    background-image:url("./assets/bg-pattern-sky-clouds-reversed-xs.png");
    background-size:640px 298px;
    height:298px;
    width:calc(100% + 640px);
  }

  @keyframes clouds-slide {
      from { transform:translate3d(-640px, 0, 0) }
      to { transform:translate3d(0, 0, 0) }
  }
}

/* ANIMATIONS ------------------------------- */
.content-view {
  transition: opacity 1s;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


</style>
