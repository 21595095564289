<template>
  <div class="content" ref="content">

    <div class="border" id="top"></div>
    <div class="border" id="bottom"></div>


    <div class="content-inner-container">

      <img class="corner" id="tl" :src="require('@/assets/assets-ornaments-img-corner-ornament-sand-tl.svg')"/>
      <img class="corner" id="tr" :src="require('@/assets/assets-ornaments-img-corner-ornament-sand-tr.svg')"/>
      <img class="corner" id="bl" :src="require('@/assets/assets-ornaments-img-corner-ornament-sand-bl.svg')"/>
      <img class="corner" id="br" :src="require('@/assets/assets-ornaments-img-corner-ornament-sand-br.svg')"/>

      <transition name="fade">
        <div id="loading-content" v-if="loadingConcept">
          <img class="loading-wheel" :src="require('@/assets/icn-compendium-loader-paper.svg')"/>
        </div>
      </transition>


      <div id="upper-block-teased" v-if="activeItem.state=='teased'">

        <img id="concept-image-teased" :src="activeItem.teasedConcept" @load="imagesLoaded"/>


        <div id="locked-block">

          <img id="locked-asset" :src="require('@/assets/reveal-flag-locked-character@2x.png')"/>

          <div id="locked-text">

            <p>A new {{activeItem.type}} reveal is only {{this.nextObjective-this.twitterFollowers}} Twitter followers away!</p>

            <p>If you are already following our account on Twitter, a great way to help us spread the word about Sea of Stars is to reshare tweets, or tell a friend about it. <br/>Thank you for the overwhelming support!</p>

          </div>

          <div id="locked-count">
            <div id="locked-count-progress">
            </div>
            <div id="locked-count-data">
                {{this.numberWithCommas(twitterFollowers)}} / {{this.numberWithCommas(nextObjective)}}
            </div>
          </div>
        </div>
      </div>

      <div id="upper-block">

        <div  id="concept" v-if="activeItem.state=='unlocked'">
          <transition name="fade">
            <img id="concept-image" :src="activeItem.concepts" v-show="!loadingConcept" @load="imagesLoaded"/>
          </transition>
        </div>

        <div id="written-content" v-if="activeItem.state=='unlocked'">

          <div class="green" id="name" v-if="activeItem.state=='unlocked'">
            <transition name="fade">
              <span v-if="!loadingConcept"> {{activeItem.name}} </span>
            </transition>
          </div>

          <transition name="fade">
            <div id="separator-pointer-wrapper" v-if="!loadingConcept">
              <img id="separator-pointer" :src="require('@/assets/reveal-img-character-line@2x.png')"/>
            </div>
          </transition>

          <div id="subtitle" v-if="activeItem.state=='unlocked' ">
            <transition name="fade">
              <span v-if="!loadingConcept"> {{activeItem.title}} </span>
            </transition>

          </div>

          <transition name="fade">
            <div id="description" v-html="activeItem.description" v-if="activeItem.state=='unlocked' && !loadingConcept">
            </div>
          </transition>

          <transition name="fade">
            <div id="extra" v-if="activeItem.state=='unlocked' && !loadingConcept">
                <li id="extra-list" v-for="extra in activeItem.extra" v-bind:key="extra.id" >
                  <div class="extra">

                    <div class="extra-name">
                      <div class="extra-icon" v-bind:class="extra.type"> </div>
                      {{getExtraName(extra.description)}}
                    </div>
                    <div class="extra-description">{{getExtraDescription(extra.description)}} </div>
                  </div>
                </li>
            </div>
          </transition>

        </div>
      </div>
      <transition name="fade">
        <img id="separator" v-if="!loadingConcept" :src="require('@/assets/assets-ornaments-img-separator-3.svg')" />
      </transition>

      <transition name="fade">
        <div id="sprites" v-if="activeItem.state=='unlocked'&& !loadingConcept">
          <li id="sprites-list" v-for="sprite in activeItem.sprites" v-bind:key="sprite.id">
            <div class="sprite">
              <div class="sprite-inner-container">
                <img class="sprite-img" :src="sprite.path"/>
              </div>
            </div>
          </li>
        </div>
      </transition>

      <transition name="fade">
        <div id="sprites" v-if="activeItem.state=='teased'">
          <li id="sprites-list">
            <div class="sprite">
              <div class="sprite-inner-container">
                <img class="sprite-img" :src="require('@/assets/img-sprite-lock.svg')" />
              </div>
            </div>
          </li>
          <li id="sprites-list" class="mobile-hidden">
            <div class="sprite">
              <div class="sprite-inner-container">
                <img class="sprite-img" :src="require('@/assets/img-sprite-lock.svg')" />
              </div>
            </div>
          </li>
          <li id="sprites-list" class="mobile-hidden">
            <div class="sprite">
              <div class="sprite-inner-container">
                <img class="sprite-img" :src="require('@/assets/img-sprite-lock.svg')" />
              </div>
            </div>
          </li>
        </div>
      </transition>

      <transition name="fade">
      <div id="screenshots" v-if="activeItem.state=='unlocked' && !loadingConcept">
        <img id="tl-sc" class="corner-screenshot" :src="require('@/assets/assets-ornaments-img-corner-ornament-screenshot-large-tl@2x.png')"/>
        <img id="tr-sc" class="corner-screenshot" :src="require('@/assets/assets-ornaments-img-corner-ornament-screenshot-large-tr@2x.png')"/>
        <img id="bl-sc" class="corner-screenshot" :src="require('@/assets/assets-ornaments-img-corner-ornament-screenshot-large-bl@2x.png')"/>
        <img id="br-sc" class="corner-screenshot" :src="require('@/assets/assets-ornaments-img-corner-ornament-screenshot-large-br@2x.png')"/>

        <div class="arrow-button" id="left" v-on:click="changeScreenshot(-1)" v-if="activeScreenshotId>1">
          <svg height="14" viewBox="0 0 18 14" width="18" xmlns="http://www.w3.org/2000/svg">
            <path d="m6.707.293 1.414 1.414-4.291 4.293h14.17v2h-14.171l4.292 4.293-1.414 1.414-6.707-6.707z" fill-rule="evenodd"/>
          </svg>
        </div>

        <div class="arrow-button" id="right" v-on:click="changeScreenshot(1)" v-if="activeScreenshotId<activeItem.screenshots.length && activeItem.screenshots.length >1 ">
          <svg height="14" viewBox="0 0 18 14" width="18" xmlns="http://www.w3.org/2000/svg">
            <path d="m11.314.293 6.707 6.707-6.707 6.707-1.414-1.414 4.292-4.293h-14.192v-2h14.192l-4.292-4.293z" fill-rule="evenodd"/>
          </svg>
        </div>

        <div class="screenshot">
          <transition name="fade">
            <div id="loading-screenshot" v-if="loadingScreenshot">
              <img class="loading-wheel" :src="require('@/assets/icn-compendium-loader.svg')"/>
            </div>
          </transition>
          <img class="screenshot-img" :src="activeItem.screenshots[activeScreenshotId-1].path" unselectable="on" width="640" height="360" @load="screenshotLoaded"/>
        </div>
      </div>
      </transition>

      <transition name="fade">
      <div id="screenshots-bullets" v-if="!loadingConcept">
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" v-for="(screenshot,index) in activeItem.screenshots" v-bind:key="screenshot.id">
          <path d="m12.075505 24c-.0565184-6.6089894-5.43989401-11.9515276-12.075505-11.9515276 6.66916878 0 12.0737388-5.39453585 12.0737388-12.0484724 0 6.60546416 5.3259742 11.9682726 11.9262612 12.0467098-6.5676123.0819624-11.8723921 5.3927732-11.924495 11.9532902z" v-bind:class="{ activeBullet: activeScreenshotId-1 == index  }" @click="setScreenshot(index+1)"/>
        </svg>
      </div>
      </transition>

      <transition name="fade">
        <div id="screenshots" v-if="activeItem.state=='teased'">
          <img id="tl-sc" class="corner-screenshot" :src="require('@/assets/assets-ornaments-img-corner-ornament-screenshot-large-tl@2x.png')"/>
          <img id="tr-sc" class="corner-screenshot" :src="require('@/assets/assets-ornaments-img-corner-ornament-screenshot-large-tr@2x.png')"/>
          <img id="bl-sc" class="corner-screenshot" :src="require('@/assets/assets-ornaments-img-corner-ornament-screenshot-large-bl@2x.png')"/>
          <img id="br-sc" class="corner-screenshot" :src="require('@/assets/assets-ornaments-img-corner-ornament-screenshot-large-br@2x.png')"/>

          <li id="screenshots-list">
            <div class="screenshot">
              <img class="screenshot-img" :src="require('@/assets/img-screenshot-lock.svg')" style="box-shadow:none;" />
            </div>
          </li>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="activeItem.bts && !loadingConcept" class="bts-container">
          <transition name="fade">
            <div class="thumbnail-container" v-if="thumbnailActive" @click="removeThumbnailAndStartVideo()">
              <div class="play-button">
                <svg class="play-button-icn" height="97" viewBox="0 0 138 97" width="138" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <path id="backshape" d="m69.0299927 0h-.0599854s-28.9670194 0-48.278816 1.39514047c-2.6975881.32147773-8.5776466.34875136-13.82718256 5.84168658-4.13750871 4.18515135-5.48393849 13.68892975-5.48393849 13.68892975s-1.38007025 11.1605837-1.38007025 22.3210323v10.462946c0 11.1603136 1.38007025 22.3207623 1.38007025 22.3207623s1.34642978 9.5039134 5.48393849 13.6890648c5.24953596 5.4930702 12.14515866 5.3193021 15.21643976 5.8950186 11.0402919 1.0580007 46.9196866 1.3854192 46.9196866 1.3854192s28.9975525-.0436108 48.3086739-1.4386162c2.697453-.3216128 8.579538-.3487514 13.827182-5.8418216 4.137509-4.1851514 5.485965-13.6890648 5.485965-13.6890648s1.378044-11.1604487 1.378044-22.3207623v-10.462946c0-11.1604486-1.378044-22.3210323-1.378044-22.3210323s-1.348456-9.5037784-5.485965-13.68892975c-5.247644-5.49293522-11.129729-5.52020885-13.827182-5.84168658-19.3111214-1.39514047-48.2788163-1.39514047-48.2788163-1.39514047"/>
                    <path id="triangle" d="m54.7468128 66.3888791 37.2882418-19.3075667-37.2882418-19.4425847z" />
                  </g>
                </svg>
              </div>
              <img class="thumbnail"  :src="activeItem.btsThumbnail" width="1280" height="720" />
            </div>

          </transition>
          <div v-if="!thumbnailActive" class="youtube-container">
            <youtube :player-vars="{ autoplay: 1 }" player-width="100%" player-height="100%" :video-id="getIdFromURL(activeItem.bts)" @playing="turnMusicOff()" @ready="playerReady"></youtube>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import {EventBus} from "@/event-bus";

export default {
  name: "contentView.vue",
  props: {
    activeItem: Object,
    twitterFollowers: Number,
    nextObjective: Number

  },
  data() {
    return {
      activeScreenshotId: 1,
      loading:false,
      loadingConcept: false,
      loadingScreenshot: false,
      player:null,
      thumbnailActive:true
    }
  },
  methods: {
    removeThumbnailAndStartVideo() {
      this.thumbnailActive = false;
      this.player.play();
    },
    playerReady: function(event) {
      this.player = event.target;
    },
    getIdFromURL:function(url) {
      return this.$youtube.getIdFromURL(url)
    },
    turnMusicOff:function() {
      EventBus.$emit('youtubePlaying');
    },
    checkIfItemIsTeased:function (field) {
      if (this.activeItem.state == 'teased')
        return this.activeItem.teasers.includes(field);
      else
        return false;
    },
    changeScreenshot:function(offset) {
      this.activeScreenshotId +=offset;
      this.loadingScreenshot = true;
    },
    imagesLoaded: function() {
      this.loadingConcept = false;
    },
    screenshotLoaded: function() {
      this.loadingScreenshot = false;
    },
    setScreenshot:function(index) {
      this.activeScreenshotId = index;
    },
    numberWithCommas:function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getExtraName: function(extra) {
      return extra.split(":")[0];
    },
    getExtraDescription: function(extra) {
      return extra.split(":")[1];
    },
    setProgressBarWidth: function(){
      var bar = document.querySelector('#locked-count-progress');
      var newWidth = 220 * (this.twitterFollowers / this.nextObjective);
          newWidth = Math.round(newWidth)

      bar.style.width = String(newWidth) + "px";
    }
  },
  updated() {
    this.$nextTick(function () {
      this.loading = false
    })

    if(this.activeItem.state=='teased'){
       this.setProgressBarWidth();
    }
  },
  mounted:function() {
    EventBus.$on('activeContentUpdated', () => {
      this.loadingConcept = true;
    })

  },
  watch: {
    activeItem: function() {
      this.activeScreenshotId = 1;
      this.thumbnailActive = true;
    }
  }

}
</script>

<style scoped>


img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

li {
  list-style-type: none;
}

.content {
  position:relative;
  display:block;
  background-image:url("../assets/assets-patterns-pattern-paper.webp");
  background-size:500px 500px;
  background-color:#F8F0E5;
  padding-top:36px;
  padding-bottom:36px;
  transition: opacity 0.1s;
}

.content-inner-container {
  display:block;
  position:relative;
  max-width:1380px;
  min-height:1200px;
  border:#e5dbcf 4px solid;
  box-sizing: border-box;
  padding:36px;
  margin:auto;
}

#loading-content {
  display:flex;
  position:absolute;
  width:100%;
  height:500px;
  padding-top:200px;
  justify-content: center;
  text-align: center;
  z-index:20;
}

.loading-wheel {
  height:100px;
}


.border {
  position: absolute;
  display:block;
  width:100%;
  height:7px;
  pointer-events: none;
}

.border#top {
  top:-7px;
  background-image:url("../assets/assets-patterns-pattern-paper-edge-top@2x.png");
  background-size:1450px 7px;
}

.border#bottom {
  bottom:-7px;
  background-image:url("../assets/assets-patterns-pattern-paper-edge-bottom@2x.png");
  background-size:1450px 7px;
  z-index:2;
}

.corner {
  position:absolute;
}

#tl {
  top:10px;
  left:10px;
}

#tr {
  top:10px;
  right:10px;
}

#br {
  bottom:10px;
  right:10px;
}

#bl {
  bottom:10px;
  left:10px;
}

#upper-block {
  display:flex;
  flex-direction: row;
}

#concept,#loading-concept {
  position:relative;
  top:-60px;
  left:-60px;
  margin-bottom:-60px;
  z-index:5;
  pointer-events: none;
}

#concept-image,#loading-concept {
  width:710px;
}
#loading-concept {
  display:block;
  height:644px;
}


#written-content {
  display:flex;
  position:relative;
  left:-40px;
  flex-direction: column;
}


#name {
  font-family: "orpheuspro";
  font-variant: small-caps;
  font-variant-ligatures: no-common-ligatures;
  color: #584b3b;
  line-height:32px;
  font-size:44px;
  text-align:left;
  font-weight:bolder;
  margin-top:24px;
}

#separator {
  margin-bottom:18px;
}

#separator-pointer-wrapper {
  display:block;
  position:relative;
  width:807px;
  pointer-events: none;
}

#separator-pointer {
  width:807px;
  position:absolute;
  left:-300px;
}

#subtitle {
  font-family: "orpheuspro";
  font-size:18px;
  color:#b18f66;
  letter-spacing: 3px;
  text-align:left;
  font-weight: bold;
  margin-top:14px;
  margin-bottom:16px;
  text-transform: uppercase;
}

#description {
  text-align:left;
  color: #584b3b;
  line-height:30px;
  font-size:16px;
  font-family: neusa-next-std-condensed;
  width:470px;
}

#description >>> p {
  margin-top:0;
  margin-bottom:12px;
}

#screenshots {
  display:block;
  position:relative;
  width:100%;
  max-width:1280px;
  margin:0 auto 15px auto;
  background-image:url("../assets/assets-patterns-pattern-paper-darker.webp");
}

#screenshots-list {
  width:100%;
}

.screenshot {
  display:block;
  width:100%;
}

.screenshot-img {
  width: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  height:auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

#loading-screenshot {
  position:absolute;
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;
 background-color:black;
}

.corner-screenshot {
  display:block;
  position:absolute;
  width:44px;
  height:44px;
  z-index: 10;
}

#tl-sc {
  top:-10px;
  left:-10px;
}

#tr-sc {
  top:-10px;
  right:-10px;
}

#bl-sc {
  bottom:-10px;
  left:-10px;
}

#br-sc {
  bottom:-10px;
  right:-10px;
}

#screenshots-bullets{
  margin:0 auto 15px auto;
  cursor: pointer;
}

#screenshots-bullets svg{
  fill:#DDCCB8;
  margin-right:8px;
}

#screenshots-bullets svg:last-child{
  margin-right:0;
}

.arrow-button {
  display:flex;
  align-items: center;
  justify-content: center;
  position:absolute;
  width:40px;
  height:40px;
  top:50%;
  background-image:url("../assets/btn-arrow-background.svg");
  cursor: pointer;
  z-index:20;
  overflow:hidden;
}

.arrow-button#left {
  left:-20px;
}

.arrow-button#right {
  right:-20px;
}

.arrow-button svg{
  fill:#48453E;
}

.arrow-button:hover svg{
  fill:#000000;
  animation: arrow-btn-move 0.3s;
  animation-timing-function: cubic-bezier(.57,.01,.35,1);
}

.arrow-button#right:hover svg{
  animation-direction: reverse;
}

@keyframes arrow-btn-move {
    0%     { transform:translate3d(0%, 0, 0);    opacity:1}
    50%    { transform:translate3d(-30px, 0, 0); opacity:0.2}
    51%    { transform:translate3d(30px, 0, 0);  opacity:0.2}
    100%   { transform:translate3d(0%, 0, 0);    opacity:1}
}

.sprite {
  position:relative;
  height:408px;
  width:408px;
  background-image:url("../assets/bkg-sprite@2x.webp");
  background-size:408px 408px;
  margin:0 auto 30px auto;
}

.sprite-img {
  width:408px;
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

.screenshot-img {
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

#sprites, #screenshots {
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:space-between;
  max-width:1280px;
}

#sprites{
  margin:auto;
}

#description >>> span {
  font-weight:600;
}

#description >>> span.green {
  color:#2ac884;
}

#description >>> span.yellow {
  color:#e7b41c;
}

#upper-block-teased {
  display:flex;
  justify-content: center;
  align-items: center;
  margin:-20px auto 0 auto;
  position:relative;
  max-width:700px;
  overflow:hidden;
}

#locked-block {
  position:absolute;
  display:flex;
  flex-direction: column;
  max-width:500px;
  margin:auto;
}


#locked-asset {
  display:block;
  width:100%;
}

#locked-text{
  max-width: 375px;
  margin: -30px auto 0 auto;
  font-family: neusa-next-std-condensed;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #584b3b;
}

#locked-text p{
  margin-top:8px;
  margin-bottom:8px;
}

#locked-text p:first-child{
  font-weight:600;
}


#locked-count{
  position:relative;
  width:220px;
  height:30px;
  margin:10px auto 0 auto;
  background-image:url("../assets/img-progress-bar-background.svg");
}


#locked-count-progress{
  position:absolute;
  width:160px;
  height:30px;
  background-image:url("../assets/img-progress-bar-progress.svg");
}

#locked-count-data{
  position:absolute;
  width:205px;
  height:30px;

  font-family:'Barlow Semi Condensed';
  font-size: 18px;
  color: #f9f2e9;
  line-height:1.55;

  background-image:url("../assets/img-progress-twitter-logo.svg");
  background-repeat:no-repeat;
  background-position:0 center;
  margin-left:15px;
}


#concept-teased {
  display:block;
}

#concept-image-teased {
  max-width:700px;
}


#extra {
  margin-top:12px;
}

.extra {
  color:#584b3b;
  display:flex;
  flex-direction: row;
  align-items: center;
  position:relative;
  max-width:470px;
  border-bottom: solid 2px #F9F4EB;
  font-family: neusa-next-std-condensed;
  background-image:url("../assets/assets-patterns-pattern-paper-darker.webp");
  border-radius: 3px;
}

li#extra-list:last-child div.extra{
  border-bottom:none;
}

.extra-icon {
  height:30px;
  width:30px;
  background-repeat:no-repeat;
  background-size:100%;
  margin-right:8px;

}

.extra-icon.weapon{
  background-image:url("../assets/reveal-icn-weapon.svg");
}

.extra-icon.quote{
  background-image:url("../assets/reveal-icn-quote.svg");
}

.extra-icon.type{
  background-image:url("../assets/reveal-icn-monster-type.svg");
}

.extra-icon.sighting{
  background-image:url("../assets/reveal-icn-location.svg");
}

.extra-icon.food{
  background-image:url("../assets/reveal-icn-favorite-food.svg");
}

.extra-icon.companion{
  background-image:url("../assets/reveal-icn-traveling-companion.svg");
}

.extra-icon.item{
  background-image:url("../assets/reveal-icn-special-item.svg");
}

.extra-icon.instrument{
  background-image:url("../assets/reveal-icn-instrument.svg");
}

.extra-icon.question{
  background-image:url("../assets/reveal-icn-question.svg");
}


.extra-icon.quote{
  background-image:url("../assets/reveal-icn-quote.svg");
}

.extra-icon.magic{
  background-image:url("../assets/reveal-icn-magic.svg");
}


.extra-name {
  display:flex;
  align-items: center;
  width:140px;
  font-size:15px;
  text-align: left;
  color:#b18f66;
  background-color: rgba(160,105,40,0.05);
  font-weight:500;
  height:38px;
  border-right: solid 2px #F9F4EB;
  padding-left:4px;


}

.extra-description {
  margin-left:12px;
  font-size:15px;

}

.bts-container {
  max-width:1280px;
  height:auto;
  margin:auto;
}

.thumbnail-container {
  display:flex;
  justify-content: center;
  align-content:center;
  align-items: center;
  vertical-align: center;
}

.thumbnail {
  max-width:100%;
  height:auto;
}

.play-button {
  display:block;
  position:absolute;
  width:138px;
  height:97px;
  z-index:10;
  cursor:pointer;
  box-shadow: 0 15px 15px -10px rgba(90, 60, 13, 0.72);
  border-radius:28px;
  transition: transform 0.2s;
  transition-timing-function:cubic-bezier(0,0,0.2,1)
}

.play-button-icn #backshape{
  fill:#0089E4;
}

.play-button-icn #triangle{
  fill:#FFF5E4;
}

.play-button:hover{
   transform:scale(1.1);
}

.play-button-icn:hover #backshape{
  fill:#1AA4FF;
}

.youtube-container  {
    overflow: hidden;
    position: relative;
    max-width:100%;
    padding-bottom: 56.25%;
}

.youtube-container >>> iframe, .youtube-container >>> object, .youtube-container >>> embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

/* SMALLER DESKTOP */
@media screen and (max-width:1320px){
  .content-inner-container {
    border:none;
    padding:0 30px;
  }

  .corner{
    display:none;
  }

  #concept {
    top:-5vw;
    left:0;
    margin-bottom:-5vw;
    width:50%;
  }

  #concept-image {
    width:100%;
  }

  #written-content{
    width:50%;
    max-width:450px;
    left:0;
  }

  #name{
    margin-top:20px;
    margin-bottom:3px;
  }

  #subtitle {
    margin-top:0px;
    margin-bottom:8px;
    letter-spacing:1px;
  }
  #description {
    width:auto;
    line-height:26px;
  }

  #separator-pointer {
    display:none;
  }

  #separator{
    max-width:100%;
  }

  #sprites{
    flex-wrap:nowrap;
  }

  .sprite{
    height:auto;
    width:100%;
    background-size: 100% 100%;
  }

  #sprites-list{
    width:32%;
  }

  .sprite-img{
    width:100%;
    height:auto;
  }

}

.activeBullet {
  fill:#0089E4;
}

/* MOBILE */

@media screen and (max-width:767px) {
  .mobile-hidden{
    display:none;
  }

  .content {
    padding-top:0;
    padding-bottom:15px;
  }

  .content-inner-container {
    border:none;
    padding:0;
    min-height:500px;
  }

  #upper-block {
    flex-direction: column;
  }

  #upper-block-teased{
    margin-top:0;
  }

  #concept {
    top:0px;
    left:0px;
    margin-bottom:0;
    width:100%;
  }

  #concept-image {
    width:100%;
  }

  #written-content {
    left:0;
    max-width:100%;
    padding:0 30px;
    width:auto;
    margin-bottom:15px;
  }

  #name{
    margin-top:0;
  }

  #separator {
    display:none;
  }

  #sprites {
    flex-direction:column;
    flex-wrap:nowrap;
    padding:0 15px;
    margin-bottom:0;
  }

  #sprites-list{
    width:auto;
  }

  .sprite{
    height:auto;
    width:100%;
    background-size: 100% 100%;
    margin-bottom:15px;
  }

  .sprite-img{
    width:100%;
    height:auto;
  }

  .corner-screenshot{
    display:none;
  }

  .arrow-button{
    top:40%;
  }

  .arrow-button#left{
    left:5px;
  }

  .arrow-button#right{
    right:5px;
  }

  .screenshot-img {
    image-rendering: auto;
    -ms-interpolation-mode: auto;
    image-rendering: auto;
    image-rendering: auto;
    image-rendering: auto;
  }

  #locked-text{
    margin-top:-20px;
  }

  #concept-image-teased{
    max-width:500px;
  }

  .play-button{
    transform:scale(0.75);
  }

  .play-button:hover{
    transform:scale(0.75);
  }

}


/* ANIMATIONS */
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-to, .fade-leave {
  opacity: 1;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}




</style>
