<template>
  <div>
    <div id="scroll-indicator">
       <img :src="require('@/assets/img-mobile-scroll-indicator.svg')" />
    </div>
    <div class="btn-scroll-arrows">
      <transition name="fade">
      <div class="btn-scroll-arrow" id="left" @click="scrollLeft()" v-show="!minReached">
         <svg height="14" viewBox="0 0 18 14" width="18" xmlns="http://www.w3.org/2000/svg">
            <path d="m6.707.293 1.414 1.414-4.291 4.293h14.17v2h-14.171l4.292 4.293-1.414 1.414-6.707-6.707z" fill-rule="evenodd"/>
          </svg>
      </div>
      </transition>
      <transition name="fade">
        <div class="btn-scroll-arrow" id="right" @click="scrollRight()" v-show="!maxReached">
          <svg height="14" viewBox="0 0 18 14" width="18" xmlns="http://www.w3.org/2000/svg">
            <path d="m11.314.293 6.707 6.707-6.707 6.707-1.414-1.414 4.292-4.293h-14.192v-2h14.192l-4.292-4.293z" fill-rule="evenodd"/>
          </svg>
        </div>
      </transition>
    </div>
    <div id="progress" ref="progress" v-on:scroll.passive="checkPosition()">
      <li class="progress-list" v-for="(portrait,index) in portraits" v-bind:key="portrait.id" ref="portrait">
        <portraitView :portrait="portrait" :active="checkIfActive(index,activeId)" :first="checkIfFirst(index)" :last="checkIfLast(index)" />
      </li>
    </div>


  </div>
</template>

<script>
import { gsap } from 'gsap';
import portraitView from './portrait.vue'
import { EventBus } from '../event-bus.js'

export default {
name: "progressBar",
  components: {
    portraitView
  },
  props: {
    portraits:Array
  },
  data (){
    return{
      activeId:0,
      portraitWidth:168,
      offset:4,
      minReached:true,
      maxReached:false

    }
  },
  mounted:function() {
    EventBus.$on('activeContentUpdated', (data) => {
      this.activeId = data;
      //console.log("SCROLL TO");
      this.scrollToActive(this.activeId);
    })
  },
  methods: {
    checkIfActive: function(index, id) {
      return index == id;
    },
    scrollToActive: function(id) {
      console.log("Set active:"+id);

      var viewportWidth = window.innerWidth;
      if(viewportWidth > 1920) viewportWidth = 1920;
      var leftOffset = this.$refs.portrait[id].offsetLeft;
      var newPosX = leftOffset - (viewportWidth * 0.5) + (this.$refs.portrait[id].offsetWidth * 0.5);

      //this.$refs.progress.scrollLeft = newPosX;
      //Moving with GSAP since smooth scroll is not Supported on Safari and iOS
      gsap.to(this.$refs.progress, {duration: 0.3, scrollLeft: newPosX});
    },
    findTeasedId: function() {

    },
    checkIfFirst: function(id) {
      return id==0;
    },
    checkIfLast: function(id) {
      return id==this.portraits.length-1;
    },
    scrollLeft:function(){
      var newPosX = this.$refs.progress.scrollLeft - (this.$refs.progress.offsetWidth * 0.5);
      if(newPosX < 0 ) newPosX = 0;

      gsap.to(this.$refs.progress, {duration: 0.75, scrollLeft: newPosX, ease:"power2.inOut"});
    },
    scrollRight:function(){
      var newPosX = this.$refs.progress.scrollLeft + (this.$refs.progress.offsetWidth * 0.5);
      var maxScroll = this.$refs.progress.scrollWidth - this.$refs.progress.offsetWidth;
      if(newPosX > maxScroll) newPosX = maxScroll;

      gsap.to(this.$refs.progress, {duration: 0.75, scrollLeft: newPosX, ease:"power2.inOut"});
    },
    checkPosition:function(){
      if (this.$refs.progress.scrollLeft > 1) {this.minReached = false} else {this.minReached = true}
      if (this.$refs.progress.scrollLeft == (this.$refs.progress.scrollWidth-this.$refs.progress.offsetWidth)) {this.maxReached = true} else {this.maxReached = false}

    }
  }
}
</script>

<style scoped>

li:first-child {
  margin-left:200px;
}

li:last-child {
  padding-right:200px;
}
#scroll-indicator{
  display:none;
}


.btn-scroll-arrows{
  z-index:500;
  position:absolute;
  width:100%;
}
.btn-scroll-arrow{
  position:absolute;
  background:#ff00ff;
  padding:40px 14px;
  margin-top:31px;
  cursor:pointer;
  background:#003B5D;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.4);

}

.btn-scroll-arrow#left{
  left:0;
  border-radius: 0 4px 4px 0;
}

.btn-scroll-arrow#right{
  right:0;
  border-radius: 4px 0 0 4px;
}

.btn-scroll-arrow svg {
  fill:#00c1ed;
}

.btn-scroll-arrow:hover{
  background:#00c1ed;
}

.btn-scroll-arrow:hover svg {
  fill:#fff;
}


#progress {
  display:flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y:visible;
  margin:auto;
  -webkit-overflow-scrolling: touch;
  position:relative;
}

#progress::-webkit-scrollbar {
  display: none;
}

.progress-list {
  display: inline;
  cursor:pointer;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media screen and (max-width:767px) {
  #scroll-indicator{
    display:block;
  }

  .btn-scroll-arrows{
    display:none;
  }

  li:first-child {
    margin-left:0px;
  }

  li:last-child {
    padding-right:0px;
  }
}

</style>
