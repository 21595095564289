import { render, staticRenderFns } from "./achievementsView.vue?vue&type=template&id=09de0de6&scoped=true&"
import script from "./achievementsView.vue?vue&type=script&lang=js&"
export * from "./achievementsView.vue?vue&type=script&lang=js&"
import style0 from "./achievementsView.vue?vue&type=style&index=0&id=09de0de6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09de0de6",
  null
  
)

export default component.exports