<template>
  <div class="achievement">

      <div class="header">
        <img class="portrait" v-if="achievement.completed" :src="achievement.portrait"/>
        <img class="portrait" v-if="!achievement.completed" :src="achievement.teasedPortrait"/>
        <img class="background-frame" v-if="achievement.completed" :src="require('@/assets/btn-timeline-medium-bkg-down@2x.png')" style="filter: drop-shadow(0px 0px 8px #00e1ff);"/>
        <img class="background-frame" v-if="!achievement.completed" :src="require('@/assets/btn-timeline-medium-bkg-up@2x.png')"/>
      </div>

      <div class="written-content-wrapper">
        <div class="title" v-bind:class="{completed:achievement.completed}">
          <img v-if="achievement.completed" :src="require('@/assets/reveal-icn-check.svg')"/>
          {{achievement.subtitle}}
        </div>

        <div class="objective">
          {{achievement.objective}}
        </div>
      </div>

  </div>
</template>

<script>
export default {
  name: "achievementView.vue",
  props: {
    achievement: Object
  }
}
</script>

<style scoped>

.header {
  position:relative;
  display:flex;
  justify-content: center;

  margin:auto;
}

.achievement {
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin:0 15px 15px 15px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.written-content-wrapper {
  max-width:194px;
}

.title {
  display:block;
  position:relative;
  color:white;
  font-family:"neusa-next-std-condensed";
  font-weight:500;
  font-size:16px;
  line-height:1.1;
  margin: 2px 0 5px 0;
}

.completed {
  color:#00e1ff;
}

.objective {
  font-family:"neusa-next-std-condensed";
  color:white;
  font-size:16px;
  line-height:1.1;
}

.description {
  color:white;
}

.portrait {
  position:relative;
  display:block;
  height:148px;
  width:122px;
  z-index:2;
}

.background-frame {
  position:absolute;
  display:block;
  height:148px;
  width:122px;
  z-index:1;
}

/* MOBILE */

@media screen and (max-width:767px) {
  .achievement {
    flex-direction: row;
    align-items: center;
    margin:0 10px 0 10px
  }

  .written-content-wrapper {
    text-align: left;
    margin-left:10px;
    max-width:100%;
  }
}

</style>
