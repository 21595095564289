<template>
  <div class="section-title">

    <img class="logo" :src="headerImage" loading="lazy" />
    <div class="title"> {{ title }} </div>
    <div class="header-description">
      <p>{{description1}}</p>
      <p>{{description2}}</p>

     </div>
  </div>
</template>

<script>
export default {
  name: "sectionTitle",
  props: {
    title:String,
    description1:String,
    description2:String,
    headerImage:String
  },
}
</script>

<style scoped>
.logo {
  display:block;
  position:relative;
  margin:auto;
  top:35px;
}

.section-title {
  padding-top:75px;
  display:block;
  position:relative;
}

.title {
  position:relative;
  display:block;
  font-family:"orpheuspro";
  text-shadow: 0px 0px 10px rgba(60,208,247,0.59);
  letter-spacing: 3.5px;
  font-size:42px;
  font-variant: small-caps;
  color:white;
  margin:0px;
}

.header-description {
  max-width:460px;
  font-family:"neusa-next-std-condensed";
  font-size:16px;
  line-height: 26px;
  font-weight:400;
  color:white;
  margin:auto;
  margin-bottom:18px;
  margin-top:-10px;
}

.header-description p{
  margin-bottom:12px;
  margin-top:12px;
}

/* MOBILE ------------------ */
@media screen and (max-width:767px) {
  .section-title {
    padding-top:40px;
  }

  .title {
    font-size:36px;
    letter-spacing: 2px;
  }

  .header-description {
    width:auto;
    margin:5px 15px 15px 15px;
  }

  .logo{
    max-width:122px;
  }
}


</style>
